import React from 'react';

import { grid, list, item, card, paginationBox, listBox, statusBox } from './post-listing.module.scss';
import { ISection } from '../../models/section.model';
import { IPost } from '../../models/post.model';
import { TStatus } from '../../models/status.model';
import useTranslations from '../../hooks/use-translations';

import Section from '../hoc/section';
import PostCard from '../molecules/post-card';
import Pagination, { IPaginationProps } from '../molecules/pagination';
import Loader from '../atoms/loader';

interface IBlogListingSection extends ISection {
    items: {
        posts: IPost[];
    };
    settings: {
        status: TStatus;
        paginationPaths: string[];
    };
}

interface IBlogListingProps {
    className?: string;
    section: IBlogListingSection;
    TitleTag?: React.ElementType;
    paginationProps?: IPaginationProps;
}

const PostListing: React.FC<IBlogListingProps> = ({ className = '', section, TitleTag = 'h2' }) => {
    const {
        sectionId,
        css,
        style,
        items: { posts },
        settings: { status, paginationPaths },
    } = section;

    const t = useTranslations('PostListing');

    return (
        <Section
            sectionId={sectionId}
            className={className}
            classes={{
                container: grid,
            }}
            TitleTag={TitleTag}
            css={css}
            style={style}
        >
            <div className={listBox}>
                {status === 'loading' && (
                    <div className={statusBox}>
                        <Loader />
                    </div>
                )}
                {status === 'error' && (
                    <div className={statusBox}>
                        <p>{t.error}</p>
                    </div>
                )}
                {status === 'success' &&
                    (posts.length > 0 ? (
                        <ul className={list}>
                            {posts.map((post) => {
                                return (
                                    <li className={item} key={`post-card-item-${post.postId}`}>
                                        <PostCard postCard={post} className={card} />
                                    </li>
                                );
                            })}
                        </ul>
                    ) : (
                        <div className={statusBox}>
                            <p>{t.empty}</p>
                        </div>
                    ))}
            </div>
            <Pagination className={paginationBox} paginationPaths={paginationPaths} />
        </Section>
    );
};

export default PostListing;