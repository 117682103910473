// extracted by mini-css-extract-plugin
export var authorBox = "post-card-module--author-box--58367";
export var categoryText = "post-card-module--category-text--d3f0f";
export var dateText = "post-card-module--date-text--adeee";
export var image = "post-card-module--image--6de21";
export var imageBox = "post-card-module--image-box--60dcf";
export var paragraph = "post-card-module--paragraph--1d196";
export var ratio = "post-card-module--ratio--75d93";
export var redirectButton = "post-card-module--redirect-button--dfbae";
export var redirectIcon = "post-card-module--redirect-icon--f2db7";
export var tagItem = "post-card-module--tag-item--283bc";
export var tagList = "post-card-module--tag-list--97ed7";
export var tagText = "post-card-module--tag-text--a062b";
export var titleText = "post-card-module--title-text--d1733";
export var wrapper = "post-card-module--wrapper--f6a68";