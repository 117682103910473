import SectionHeader from '../components/sections/header';
import SectionBreadcrumbs from '../components/sections/breadcrumbs';
import SectionPostHeader from '../components/sections/post-header';
import SectionPostListing from '../components/sections/post-listing';
import SectionInstagram from '../components/sections/instagram';
import SectionFooter from '../components/sections/footer';

import React, { useMemo } from 'react';
import { graphql } from 'gatsby';
import { IPageContext, usePageContext } from '@alterpage/gatsby-plugin-alterpress-page-creator';

import { endpoints } from '../config/endpoints';
import { IPage } from '../models/page.model';
import { IPostCategory } from '../models/post-category.model';
import { IActiveFilter } from '../models/active-filter.model';
import { IPostTag } from '../models/post-tag.model';
import { IPost } from '../models/post.model';
import { useList } from '../hooks/use-list';
import useTranslations from '../hooks/use-translations';

import MainLayout from '../layouts/main-layout';
import SectionFactory from '../components/hoc/section-factory';
import UpwardsButton from '../components/molecules/upwards-button';

interface IBlogProps {
    readonly data: {
        page: IPage;
        blogPage: Pick<IPage, 'pathname'>;
        postTag: IPostTag | null;
        postCategory: IPostCategory | null;
    };
}

interface IBlogPageContext extends IPageContext {
    tagId?: number;
    categoryId?: number;
}

const Blog: React.FC<IBlogProps> = ({ data }) => {
    const t = useTranslations('BlogTemplate');
    const { page, blogPage, postTag, postCategory } = data;
    const { tagId, categoryId }: IBlogPageContext = usePageContext();
    const { sections } = page;

    const additionalParams = useMemo(() => {
        const activeFilters = categoryId
            ? postCategory?.activeFilters
            : tagId
            ? postTag?.activeFilters
            : undefined;
        return getAdditionalParams(tagId, categoryId, activeFilters);
    }, [tagId, categoryId]);

    const {
        items: posts,
        status,
        paginationPaths,
        handleSearch,
        initialSearchValue,
    } = useList<IPost>({
        endpoint: endpoints.posts,
        additionalParams,
    });

    const sectionsWithData = sections.map((section) => {
        if (section.type === 'post-header' && !tagId) {
            const sectionCategories = (section.items?.categories as IPostCategory[]) || [];
            const categoriesWithAll =
                sectionCategories.length > 0
                    ? [
                          { categoryId: 0, title: t.allLabel, pathname: blogPage.pathname },
                          ...sectionCategories,
                      ]
                    : [];
            return {
                ...section,
                items: {
                    categories: categoriesWithAll,
                },
                settings: {
                    onSearch: handleSearch,
                    initialSearchValue,
                },
            };
        }
        if (section.type === 'post-header' && tagId && postTag) {
            return {
                ...section,
                content: {
                    ...section.content,
                    texts: [
                        `#${postTag.name}`,
                        null,
                        `Wszystkie artykuły z tagiem <span>${postTag.name}<span>`,
                    ],
                },
                settings: {
                    context: 'tag',
                    onSearch: handleSearch,
                    initialSearchValue,
                },
                items: {},
            };
        }
        if (section.type === 'post-listing') {
            return {
                ...section,
                items: { posts },
                settings: { status, paginationPaths },
            };
        }
        return section;
    });

    return (
        <MainLayout>
            {sectionsWithData.map((section) => {
                return (
                    <SectionFactory
                        key={`section-${section.type}-${section.sectionId}`}
                        SectionComponent={sectionComponents[section.type]}
                        section={section}
                    />
                );
            })}
            <UpwardsButton />
        </MainLayout>
    );
};

function getAdditionalParams(
    tagId?: number,
    postCategoryId?: number,
    activeFilters?: IActiveFilter
): Record<string, string> | undefined {
    if ((tagId || postCategoryId) && activeFilters) {
        return { [activeFilters.paramName]: activeFilters.value };
    }
    return;
}

export const query = graphql`
    query ($pageId: Int!, $locale: String!, $tagId: Int, $categoryId: Int) {
        page(pageId: { eq: $pageId }, locale: { eq: $locale }) {
            pathname
            type
            sections {
                ...sectionFields
            }
        }
        blogPage: page(type: { eq: "blog" }, locale: { eq: $locale }) {
            pathname
        }
        postTag(tagId: { eq: $tagId }, locale: { eq: $locale }) {
            tagId
            name
            activeFilters {
                paramName
                value
            }
        }
        postCategory(categoryId: { eq: $categoryId }, locale: { eq: $locale }) {
            activeFilters {
                paramName
                value
            }
        }
    }
`;

export default Blog;

export { Head } from '@alterpage/gatsby-plugin-alterpress-page-creator';



const sectionComponents: Record<string, any> = {
    'header': SectionHeader,
    'breadcrumbs': SectionBreadcrumbs,
    'post-header': SectionPostHeader,
    'post-listing': SectionPostListing,
    'instagram': SectionInstagram,
    'footer': SectionFooter,
};